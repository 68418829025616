import React, { useEffect, useState } from "react";
import texture from "../assets/About/Texture.png";
import solalrpanel from "../assets/Products/SolarPanel.png";
import solaraccessories from "../assets/Products/Accessories.png";
import gridinverter from "../assets/Products/Gridinverter.png";
import inverter from "../assets/Products/Inverter.png";
import "./products.css";
import AOS from "aos";
import "aos/dist/aos.css";

const OurProducts = () => {
  const [activeCard, setActiveCard] = useState("1");

  const [activeCard2, setActiveCard2] = useState("1");

  const contentData = [
    {
      id: "1",
      title: "Solar Panels",
      description1:
        "Solar panels possess high-quality monocrystalline modules that are efficient, reliable, and durable. Also, the panel price remains reasonable and affordable to the audiences.",
      description2:
        "Solar panels are paramount to making a 20% operational efficiency. The panels produce a lot of electricity and generate a tremendous amount of energy. Even in low-light settings, such as dawn, dusk, and overcast skies days, panels deliver a substantial quantity of electricity.",
      // image: solalrpanel,
      background: texture,
    },
    {
      id: "2",
      title: "On Grid Solar System",
      description1:
        "An On-Grid Inverter is a vital component of solar energy systems connected to the main power grid. It converts the direct current (DC) generated by solar panels into alternating current (AC), compatible with the electricity supplied by the grid. This ensures seamless energy integration, allowing excess power to be fed back into the grid, often earning credits through net metering.",
      // description2:
      //   "These inverters are highly efficient and cost-effective, making them ideal for residential, commercial, and industrial applications. They eliminate the need for battery storage, reducing maintenance and upfront costs while maximizing energy utilization.",
      // image: gridinverter,
      background: texture,
    },
    {
      id: "3",
      title: "OFF Grid Solar System",
      description1:
        "Radiant Sun Energy LLP’s OFF Grid Inverter ensures seamless power backup in locations without grid connectivity. With high efficiency, it converts solar energy into reliable electricity for homes and businesses. Durable and robust, it supports sustainable energy independence while delivering optimal performance, even in remote or rural areas.",
      // description2:
      //   "High-quality solar accessories ensure optimal energy transmission, durability, and weather resistance, making them indispensable for long-term performance. They are designed to complement solar panels and inverters, facilitating proper installation, connectivity, and maintenance.",
      // image: solaraccessories,
      background: texture,
    },
    {
      id: "4",
      title: "Hybrid Solar System",
      description1:
        "Our Hybrid Solar System combines solar power, grid supply, and battery storage for unparalleled energy flexibility. It intelligently manages energy flow to minimize electricity bills while ensuring uninterrupted power. Ideal for residential and commercial use, this system ensures energy efficiency and long-term cost savings for sustainable living.",
      // description2:
      //   "These inverters are commonly used in off-grid and hybrid solar systems, ensuring energy independence and reliability. Advanced battery inverters come equipped with smart features like load management, remote monitoring, and compatibility with various battery types, including lithium-ion and lead-acid.",
      // image: inverter,
      background: texture,
    },
  ];
  const contentData2 = [
    {
      id: "1",
      title: "LT Panel",
      description1:
        "Radiant Sun Energy LLP’s LT Panels are engineered for low-tension electrical distribution. Built with premium quality materials, these panels ensure efficient energy management and safety. Perfect for industrial and commercial setups, they provide durability, reliability, and optimized performance for modern energy needs.",
      // description2:
      //   "These inverters are commonly used in off-grid and hybrid solar systems, ensuring energy independence and reliability. Advanced battery inverters come equipped with smart features like load management, remote monitoring, and compatibility with various battery types, including lithium-ion and lead-acid.",
      // image: inverter,
      background: texture,
    },
    {
      id: "2",
      title: "HT Panel",
      description1:
        "Our HT Panels are crafted for high-tension power distribution, ensuring safety and efficiency for large-scale electrical systems. Designed with cutting-edge technology, these panels offer enhanced control, durability, and superior energy handling, making them the perfect choice for industries requiring high-voltage power solutions.",
      // description2:
      //   "These inverters are commonly used in off-grid and hybrid solar systems, ensuring energy independence and reliability. Advanced battery inverters come equipped with smart features like load management, remote monitoring, and compatibility with various battery types, including lithium-ion and lead-acid.",
      // image: inverter,
      background: texture,
    },
    {
      id: "3",
      title: "Solar Light",
      description1:
        "Illuminate your spaces with Radiant Sun Energy LLP’s Solar Lights, blending energy efficiency with eco-friendliness. Powered by sunlight, these lights offer bright, long-lasting illumination for outdoor and indoor settings. Easy to install and maintain, they are the perfect sustainable solution for lighting needs.",

      // description2:
      //   "These inverters are commonly used in off-grid and hybrid solar systems, ensuring energy independence and reliability. Advanced battery inverters come equipped with smart features like load management, remote monitoring, and compatibility with various battery types, including lithium-ion and lead-acid.",
      // image: inverter,
      background: texture,
    },
    {
      id: "4",
      title: "Solar BOS",
      description1:
        "Our Solar Balance of System (BOS) components ensure seamless integration and functionality of your solar setup. From wiring to mounting structures, Radiant Sun Energy LLP provides high-quality BOS solutions to maximize system efficiency, reliability, and longevity, empowering you to harness solar energy effectively.",
      // description2:
      //   "These inverters are commonly used in off-grid and hybrid solar systems, ensuring energy independence and reliability. Advanced battery inverters come equipped with smart features like load management, remote monitoring, and compatibility with various battery types, including lithium-ion and lead-acid.",
      // image: inverter,
      background: texture,
    },
  ];

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <>
      <div className="container py-4">
        <div className="products col-12 text-center" data-aos="zoom-in">
          <h1 className="display-2 fw-bold">
            Our{" "}
            <span
              style={{
                background: "linear-gradient(to right, #FF5E43, #FB992E)",
                WebkitBackgroundClip: "text",
                color: "transparent",
              }}
            >
              Products
            </span>
          </h1>
          <h5 className="fw-semibold">
            We, Radiant Sun Energy LLP in Anand, Gujarat, are a leading dealer
            of solar energy systems and other products. We design and
            manufacture high quality, aesthetically pleasing devices aimed at
            enhancing your outdoor, off-grid experience. We deal in
            environmentally safe products such as solar roofs, solar sheets,
            solar geysers etc. Our aim is to provide the best products to our
            customers through excellent service.
          </h5>
          {/* <div className="button">
            <button
              className="justify-content-center text-white fw-bold fs-5 border py-2 rounded-pill px-4"
              style={{
                background: "linear-gradient(to right, #FF5E43,#FB992E)",
                whiteSpace: "nowrap",
              }}
            >
              Get Quote
            </button>
          </div> */}
        </div>
      </div>

      {/* Cards */}
      <div className="container-fluid py-5 p-4">
        <div
          className="btn  buttons position-absolute col-12  start-0 p-2 d-flex justify-content-evenly border-0 py-5 py-md-5 flex-wrap"
          style={{ zIndex: "2", width: "100%" }}
        >
          {contentData.map((item) => (
            <button
              key={item.id}
              className={`bg-white card-btn  bg-opacity-25 col-10 col-sm-5 col-md-5 col-lg-2 rounded-4 p-2 mt-2 text-white fw-semibold fs-4 border-0 mb-2 ${
                activeCard === item.id ? "active-btn" : ""
              }`}
              onClick={() => setActiveCard(item.id)}
            >
              {item.title}
            </button>
          ))}
        </div>

        {contentData.map(
          (item) =>
            item.id === activeCard && (
              <div
                key={item.id}
                className="mainCard p-4  rounded-5 position-relative overflow-hidden mb-4 col-11 m-auto"
                style={{
                  minHeight: "650px",
                  width: "100%",
                  background: "rgb(25,42,64)",
                }}
              >
                {/* Background Image */}
                <img
                  className="img-fluid"
                  src={item.background}
                  alt="Background Texture"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    objectFit: "cover",
                    zIndex: "-1",
                    height: "100%",
                    width: "100%",
                  }}
                />

                {/* Content Section */}
                <div
                  data-aos="zoom-in"
                  // className="text-white main-content mt-4   content p-4 d-flex justify-content-between"
                  className="text-white main-content mt-4   content p-4 d-flex justify-content-center align-items-center"
                  style={{
                    position: "absolute",
                    // bottom: "0",
                    top: "120px",
                    left: "0",
                    right: "0",
                    maxHeight: "80%",
                    zIndex: "2",
                  }}
                >
                  {/* Text Content */}
                  <div className="text-content  col-12 custom-padding  mt-2 col-md-7">
                    <h2 className="fw-bold title display-3">{item.title}</h2>
                    <p className="desc1 ">{item.description1}</p>
                    <p className="desc2">{item.description2}</p>
                  </div>

                  {/* Image Section */}
                  {/* <div className="img-fluid d-flex justify-content-center p-4 col-12 col-md-4">
                    <img
                      className="h-100 w-100 img-fluid2"
                      src={item.image}
                      alt={item.title}
                      style={{
                        objectFit: "contain",
                        maxWidth: "100%",
                        height: "auto",
                      }}
                    />
                  </div> */}
                </div>
              </div>
            )
        )}
      </div>

      {/* cards2 */}
      <div className="container-fluid py-5 p-4">
        <div
          className="btn  buttons position-absolute col-12  start-0 p-2 d-flex justify-content-evenly border-0 py-5 py-md-5 flex-wrap"
          style={{ zIndex: "2", width: "100%" }}
        >
          {contentData2.map((item) => (
            <button
              key={item.id}
              className={`bg-white card-btn  bg-opacity-25 col-10 col-sm-5 col-md-5 col-lg-2 rounded-4 p-2 mt-2 text-white fw-semibold fs-4 border-0 mb-2 ${
                activeCard2 === item.id ? "active-btn" : ""
              }`}
              onClick={() => setActiveCard2(item.id)}
            >
              {item.title}
            </button>
          ))}
        </div>

        {contentData2.map(
          (item) =>
            item.id === activeCard2 && (
              <div
                key={item.id}
                className="mainCard p-4  rounded-5 position-relative overflow-hidden mb-4 col-11 m-auto"
                style={{
                  minHeight: "650px",
                  width: "100%",
                  background: "rgb(25,42,64)",
                }}
              >
                {/* Background Image */}
                <img
                  className="img-fluid"
                  src={item.background}
                  alt="Background Texture"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    objectFit: "cover",
                    zIndex: "-1",
                    height: "100%",
                    width: "100%",
                  }}
                />

                {/* Content Section */}
                <div
                  data-aos="zoom-in"
                  className="text-white main-content mt-4  d-flex justify-content-center align-items-center   content p-4 "
                  style={{
                    position: "absolute",

                    top: "120px",
                    left: "0",
                    right: "0",
                    maxHeight: "80%",
                    zIndex: "2",
                  }}
                >
                  {/* Text Content */}
                  <div className="text-content  col-12 custom-padding   mt-2 col-md-7">
                    <h2 className="fw-bold  title display-3">{item.title}</h2>
                    <p className="desc1">{item.description1}</p>
                    <p className="desc2">{item.description2}</p>
                  </div>

                  {/* Image Section */}
                  {/* <div className="img-fluid d-flex justify-content-center p-4 col-12 col-md-4">
                    <img
                      className="h-100 w-100 img-fluid2"
                      src={item.image}
                      alt={item.title}
                      style={{
                        objectFit: "contain",
                        maxWidth: "100%",
                        height: "auto",
                      }}
                    />
                  </div> */}
                </div>
              </div>
            )
        )}
      </div>
    </>
  );
};

export default OurProducts;
