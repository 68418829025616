import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import img1 from "../assets/img1.png";
import GetQuote from "./GetQuote";
import Inquiry from "../pages/Inquiry";
import "./header.css";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  const menus = [
    {
      id: "1",
      title: "Home",
      link: "/",
    },
    { id: "2", title: "Our Projects", link: "/projects" },
    { id: "3", title: "Our Products", link: "/products" },
    { id: "4", title: "About Us", link: "/about-us" },
    { id: "5", title: "Contact Us", link: "/contact-us" },
  ];

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isMenuOpen]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    // Show header initially or when scrolling up, hide on scroll down
    if (currentScrollY > 50) {
      if (currentScrollY > lastScrollY) {
        setShowHeader(false); // Hide on scroll down
      } else {
        setShowHeader(true); // Show on scroll up
      }
    } else {
      setShowHeader(true); // Show header when near the top
    }

    setLastScrollY(currentScrollY);
  };

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [lastScrollY]);

  return (
    <>
      <nav
        style={{ height: "11.5vh", width: "100%" }}
        // className={`navbar navbar-expand-lg fixed-top header-transition border border-2  ${
        className={`navbar navbar-expand-lg fixed-top   header-transition    ${
          showHeader ? "header-visible" : "header-hidden"
        }`}
      >
        <div className="container-fluid px-lg-4    d-flex align-items-center">
          <img
            style={{ zIndex: 1000 }}
            className="logo"
            src={img1}
            alt="Logo"
          />

          {!isMobile && (
            <ul
              className="navbar-nav fs-3  fw-bold d-flex justify-content-center align-items-center mx-auto mb-2 mb-lg-0"
              style={{ width: "100%" }}
            >
              <div className="d-flex gap-3 justify-content-center  ">
                {menus.map((item) => (
                  <li className="nav-item" key={item.id}>
                    <Link
                      className="nav-link fs-3 "
                      to={item.link}
                      style={{
                        background:
                          "linear-gradient(to right, #FF5E43, #FB992E)",
                        WebkitBackgroundClip: "text",
                        color: "transparent",
                      }}
                    >
                      {item.title}
                    </Link>
                  </li>
                ))}
              </div>
            </ul>
          )}

          {/* Get Quote Button */}
          {!isMobile && (
            <Link
              to="/inquiry"
              className="d-flex justify-content-center align-items-center text-white fw-bold fs-5 border-0 py-2 rounded-pill px-4 ms-5"
              style={{
                background: "linear-gradient(to right, #FF5E43,#FB992E)",
                whiteSpace: "nowrap",
                textDecoration: "none",
              }}
            >
              Get Quote
            </Link>
          )}

          {/* Mobile Menu Icon */}
          {isMobile && (
            <div
              className="d-flex gap-5 justify-content-center align-items-center"
              style={{ zIndex: 1000 }}
            >
              <Link
                to="/get-quote"
                className="btn text-white fw-bold border-0 py-2 rounded-pill px-4"
                style={{
                  background: "linear-gradient(to right, #FF5E43,#FB992E)",
                  textDecoration: "none",
                }}
              >
                Get Quote
              </Link>

              {isMenuOpen ? (
                <svg
                  onClick={toggleMenu}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  width="40"
                  height="40"
                  className="bi bi-x"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                  />
                </svg>
              ) : (
                <svg
                  onClick={toggleMenu}
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="currentColor"
                  className="bi bi-list"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
                  />
                </svg>
              )}
            </div>
          )}
        </div>

        {/* Fullscreen Menu */}
        <div
          style={{ zIndex: 100 }}
          className={`fullscreen-menu ${isMenuOpen ? "open" : ""}`}
        >
          <div className="d-flex flex-column    justify-content-center align-items-center">
            <ul className="navbar-nav  fs-1 fw-bold gap-2 mx-auto">
              {menus.map((item) => (
                <li className="nav-item" key={item.id}>
                  <Link
                    className="nav-link"
                    to={item.link}
                    onClick={() => setIsMenuOpen(false)}
                    style={{
                      background: "linear-gradient(to right, #FF5E43, #FB992E)",
                      WebkitBackgroundClip: "text",
                      color: "transparent",
                    }}
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>

            <Link
              to="/inquiry"
              onClick={() => setIsMenuOpen(false)}
              className="mt-3 btn-menu   text-white fw-bold border-0   py-2 rounded-pill px-4 "
              style={{
                background: "linear-gradient(to right, #FF5E43,#FB992E)",
                textDecoration: "none",
              }}
            >
              Get Quote
            </Link>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
