import React, { useEffect, useState } from "react";
import Texture from "../assets/Projects/Texture.png";
import SolarImage from "../assets/Projects/Solar.png";
import homeSolar1 from "../assets/Projects/homeSolar1.png";
import homeSolar2 from "../assets/Projects/homeSolar2.png";
import homeSolar3 from "../assets/Projects/homeSolar3.png";
import industrialSolar1 from "../assets/Projects/industrialSolar1.png";
import industrialSolar2 from "../assets/Projects/industrialSolar2.png";
import "./projects.css";
import AOS from "aos";
import "aos/dist/aos.css";

const Projects = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const cards = [
    {
      id: 1,
      title: "Home Roof Top",
      location: "Vivekanand Vadi, Anand",
      image: homeSolar1,
    },
    {
      id: 2,
      title: "Industrial Roof Top",
      location: "Vadodara",
      image: industrialSolar1,
    },
    {
      id: 3,
      title: "Home Roof Top",
      location: "Karamsad",
      image: homeSolar2,
    },
    {
      id: 4,
      title: "Industrial Roof Top",
      location: "Petlad",
      image: industrialSolar2,
    },
    {
      id: 5,
      title: "Home Roof Top",
      location: "Nadiad",
      image: homeSolar3,
    },
    {
      id: 6,
      title: "Industrial Roof Top",
      location: "GIDC, Ahmedabad",
      image: SolarImage,
    },
  ];

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  const openModal = (index) => {
    setCurrentImageIndex(index);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => {
      const newIndex = (prevIndex + 1) % cards.length;
      console.log("Next Image Index:", newIndex);
      return newIndex;
    });
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => {
      const newIndex = (prevIndex - 1 + cards.length) % cards.length;
      console.log("Previous Image Index:", newIndex);
      return newIndex;
    });
  };

  return (
    <>
      <div className="container py-5">
        {/* Section 1 */}
        <div data-aos="zoom-in" className="text-center">
          <div className="products col-12">
            <h1 className="display-2 fw-bold">
              Our Recent{" "}
              <span
                style={{
                  background: "linear-gradient(to right, #FF5E43, #FB992E)",
                  WebkitBackgroundClip: "text",
                  color: "transparent",
                }}
              >
                Projects
              </span>
            </h1>
            <p className="fw-semibold fs-5">
              We, Radiant Sun Energy LLP in Anand, Gujarat, are a leading
              dealers of solar energy systems and other products. We design and
              manufacture high quality, aesthetically pleasing devices aimed at
              enhancing your outdoor, off-grid experience. We deal in
              environmentally safe products such as solar roofs, solar sheets,
              solar geyser etc. Our aim is to provide the best products to our
              customers through excellent service.
            </p>
          </div>
        </div>
      </div>

      {/* Section 2 */}
      <div className="container-fluid p-3">
        <div className="row">
          {cards.map((card, index) => (
            <div
              data-aos="fade-up"
              key={card.id}
              className="col-12 col-sm-12 col-md-6 col-lg-6 py-3 px-3"
            >
              <div
                className="position-relative overflow-hidden rounded-5 cards"
                style={{ height: "80vh", cursor: "pointer" }}
                onClick={() => openModal(index)}
              >
                {/* Solar Image */}
                <img
                  src={card.image}
                  alt={card.title}
                  className="img-fluid h-100 w-100 image"
                  style={{ objectFit: "cover" }}
                />
                {/* Texture Image */}
                <div
                  className="position-absolute top-0 start-0 h-100 w-100"
                  style={{ zIndex: 2, pointerEvents: "none" }}
                >
                  <img
                    src={Texture}
                    alt="Texture Overlay"
                    className="img-fluid h-100 w-100"
                    style={{ objectFit: "cover" }}
                  />
                </div>
                {/* Text */}
                <div
                  className="position-absolute text-white text-start p-3"
                  style={{ bottom: "20px", left: "20px", zIndex: 3 }}
                >
                  <h1 className="fw-bold fs-3">{card.title}</h1>
                  <p className="fs-5">{card.location}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Modal */}
      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-btn" onClick={closeModal}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="white"
                class="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
              </svg>
            </button>
            <button className="nav-btn left" onClick={prevImage}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="white"
                class="bi bi-arrow-left"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
                />
              </svg>
            </button>
            <img
              src={cards[currentImageIndex].image}
              alt={`Project ${currentImageIndex + 1}`}
              style={{
                width: "100%",
                height: "auto",
                display: "block",
              }}
            />
            <button className="nav-btn right" onClick={nextImage}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="white"
                class="bi bi-arrow-right"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Projects;
