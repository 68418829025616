import React, { useState } from "react";
import Swal from "sweetalert2";
import "./inquiry.css";
import { useNavigate } from "react-router-dom";

const Inquiry = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fullName: "",
    address: "",
    phoneNumber: "",
    email: "",
    // inquiry: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { fullName, address, phoneNumber, email } = formData;
    if (!fullName || !address || !phoneNumber || !email) {
      Swal.fire({
        title: "Error",
        html: "<p style='font-weight: bold;'>Please fill out all the fields before submitting.</p>",
        icon: "error",

        scrollbarPadding: false,
      });

      return;
    }

    Swal.fire({
      title: "Successfully Submitted!",
      html: "<p style='font-weight: bold;'>Your form has been submitted successfully!</p>",
      icon: "success",

      scrollbarPadding: false, // Scrollbar padding disable
    }).then(() => {
      // Scroll behavior ko restore karein
      document.body.style.overflow = "auto";
      navigate("/get-quote"); // Navigate to the Get Quote form
    });

    setFormData({
      fullName: "",
      address: "",
      phoneNumber: "",
      email: "",
      // inquiry: "",
    });
  };
  return (
    <>
      <div className="py-5 main-container">
        <div className="container-fluid inquiry-form p-4">
          <h2 className="text-center  fw-bold fs-1">Inquiry Form</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-1">
              <label htmlFor="fullName" className="form-label fs-5 fw-semibold">
                Full Name
              </label>
              <input
                type="text"
                className="form-control fs-6 fw-semibold"
                id="fullName"
                name="fullName"
                value={formData.fullName}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-1">
              <label htmlFor="address" className="form-label fs-5 fw-semibold">
                Address
              </label>
              <input
                type="text"
                className="form-control fs-6 fw-semibold"
                id="address"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-1">
              <label
                htmlFor="phoneNumber"
                className="form-label fs-5 fw-semibold"
              >
                Phone Number
              </label>
              <input
                type="phone"
                className="form-control fs-6 fw-semibold"
                id="phoneNumber"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-1">
              <label htmlFor="email" className="form-label fs-5 fw-semibold">
                Email
              </label>
              <input
                type="email"
                className="form-control fs-6 fw-semibold"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
            </div>
            {/* <div className="mb-3">
              <label htmlFor="inquiry" className="form-label fs-5 fw-semibold">
                Inquiry Type
              </label>
              <select
                className="form-select fs-5 fw-semibold"
                id="inquiry"
                name="inquiry"
                value={formData.inquiry}
                onChange={handleInputChange}
              >
                <option className="fs-6 fw-semibold" value="">
                  Select Inquiry Type
                </option>
                <option className="fs-6 fw-semibold" value="General">
                  General Inquiry
                </option>
                <option className="fs-6 fw-semibold" value="Product">
                  Product Inquiry
                </option>
                <option className="fs-6 fw-semibold" value="Support">
                  Support
                </option>
              </select>
            </div> */}
            <button
              type="submit"
              className="btn submit-btn d-inline d-md-block mt-3 "
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Inquiry;

// import React, { useState } from "react";
// import Swal from "sweetalert2";
// import "./contact.css";

// const Inquiry = ({ moveToGetQuote }) => {
//   const [formData, setFormData] = useState({
//     fullName: "",
//     address: "",
//     phoneNumber: "",
//     email: "",
//     inquiry: "",
//   });

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     const { fullName, address, phoneNumber, email, inquiry } = formData;
//     if (!fullName || !address || !phoneNumber || !email || !inquiry) {
//       Swal.fire({
//         title: "Error",
//         html: "<p style='font-weight: bold;'>Please fill out all the fields before submitting.</p>",
//         icon: "error",
//         scrollbarPadding: false,
//       });
//       return;
//     }

//     Swal.fire({
//       title: "Successfully Submitted!",
//       html: "<p style='font-weight: bold;'>Your form has been submitted successfully!</p>",
//       icon: "success",
//       scrollbarPadding: false,
//     }).then(() => {
//       document.body.style.overflow = "auto";
//       moveToGetQuote(); // Navigate to the Get Quote form
//     });

//     setFormData({
//       fullName: "",
//       address: "",
//       phoneNumber: "",
//       email: "",
//       inquiry: "",
//     });
//   };

//   return (
//     <div className="outer-container">
//       <div className="container-fluid inquiry-form p-4">
//         <h2 className="text-center mb-2 fw-bold fs-1">Inquiry Form</h2>
//         <form onSubmit={handleSubmit}>
//           <div className="mb-1">
//             <label htmlFor="fullName" className="form-label fs-5 fw-semibold">
//               Full Name
//             </label>
//             <input
//               type="text"
//               className="form-control fs-6 fw-semibold"
//               id="fullName"
//               name="fullName"
//               value={formData.fullName}
//               onChange={handleInputChange}
//             />
//           </div>
//           <div className="mb-1">
//             <label htmlFor="address" className="form-label fs-5 fw-semibold">
//               Address
//             </label>
//             <input
//               type="text"
//               className="form-control fs-6 fw-semibold"
//               id="address"
//               name="address"
//               value={formData.address}
//               onChange={handleInputChange}
//             />
//           </div>
//           <div className="mb-1">
//             <label
//               htmlFor="phoneNumber"
//               className="form-label fs-5 fw-semibold"
//             >
//               Phone Number
//             </label>
//             <input
//               type="phone"
//               className="form-control fs-6 fw-semibold"
//               id="phoneNumber"
//               name="phoneNumber"
//               value={formData.phoneNumber}
//               onChange={handleInputChange}
//             />
//           </div>
//           <div className="mb-1">
//             <label htmlFor="email" className="form-label fs-5 fw-semibold">
//               Email
//             </label>
//             <input
//               type="email"
//               className="form-control fs-6 fw-semibold"
//               id="email"
//               name="email"
//               value={formData.email}
//               onChange={handleInputChange}
//             />
//           </div>
//           <div className="mb-3">
//             <label htmlFor="inquiry" className="form-label fs-5 fw-semibold">
//               Inquiry Type
//             </label>
//             <select
//               className="form-select fs-5 fw-semibold"
//               id="inquiry"
//               name="inquiry"
//               value={formData.inquiry}
//               onChange={handleInputChange}
//             >
//               <option value="">Select Inquiry Type</option>
//               <option value="General">General Inquiry</option>
//               <option value="Product">Product Inquiry</option>
//               <option value="Support">Support</option>
//             </select>
//           </div>
//           <button type="submit" className="btn submit-btn d-inline d-md-block">
//             Submit & Continue
//           </button>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default Inquiry;
