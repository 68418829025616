import React, { useEffect, useRef, useState } from "react";
import CountUp from "react-countup";

import { gsap } from "gsap";
import "../App.css";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import light from "../assets/light.png";
import layer2 from "../assets/layer2.png";
import solar from "../assets/solar.png";
import solars from "../assets/Solars.png";

import sky from "../assets/sky.png";

import solarbackground from "../assets/solarbackground.png";

gsap.registerPlugin(ScrollTrigger);

const Home = () => {
  // Extracts pathname property(key) from an object
  //  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const textRef = useRef(null);
  const boxesRef = useRef([]);

  const sectionRef = useRef(null);

  const reviewSectionRef = useRef(0);

  // const [currentIndex, setCurrentIndex] = useState(0);

  // const [direction, setDirection] = useState("next");

  // section3
  const boxContent = [
    {
      title: "Expert Solar Panel Installation",
      description:
        "Professional installation for efficient, reliable solar energy solutions.",
    },
    {
      title: "Tailored Energy Assessments",
      description:
        "Customized evaluations to optimize your solar system's performance.",
    },
    {
      title: "Ongoing Maintenance Services",
      description: "Regular upkeep to keep your solar system running smoothly.",
    },
    {
      title: "Sustainable Energy Consulting",
      description:
        "Advising on eco-friendly practices and maximizing solar benefits.",
    },
  ];
  // section 4
  const reviewItems = [
    { title: "Industrial Solar Installation", count: 100 },
    { title: "Residential Solar Installation", count: 1000 },
    { title: "Subsidy Transferred", count: 30000000 },
  ];
  const formatCount = (count) => {
    if (count >= 10000000) {
      return (count / 10000000).toFixed(1); // Converts large numbers to crore format
      // } else if (count >= 1000000) {
      //   return (count / 100000).toFixed(1);
    } else if (count >= 10000) {
      return Math.floor(count / 1000);
    }
    return count;
  };

  useEffect(() => {
    // window.scrollTo(0, 0);
    // section 1
    const tl = gsap.timeline({
      scrollTrigger: {
        start: "top top",
        end: "bottom top",
        scrub: 1.5,
      },
    });
    tl.fromTo(
      ".main-heading",
      { opacity: 0, y: 10 },
      { opacity: 1, y: 30, duration: 0.1 },
      "<"
    ).fromTo(".solar-image", { y: -50 }, { y: 260, duration: 0.5 }, "<");

    // section 2
    const section = sectionRef.current;

    // GSAP fade-up animation
    gsap.fromTo(
      section,
      { opacity: 0, y: 50 },
      {
        opacity: 1,
        y: 0,
        ease: "power3.inOut",
        duration: 1.3,
        scrollTrigger: {
          trigger: section,
          start: "top 80%",
          toggleActions: "restart none none none",
        },
      }
    );

    // section 3
    // Text Animation on Mount

    gsap.fromTo(
      textRef.current,
      { y: 100, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        duration: 1.5,
        ease: "power3.out",
        scrollTrigger: {
          trigger: textRef.current,
          start: "top 75%",
          end: "top 30%",
          scrub: 1,
        },
      }
    );

    // Animate each box with smoother transitions
    boxesRef.current.forEach((box, index) => {
      gsap.fromTo(
        box,
        { y: 100, opacity: 0, rotation: 0 },
        {
          y: -200,
          opacity: 1,

          rotation: -20,
          duration: 2.5,
          delay: index * 0.4 + 0.6,
          ease: "power3.out",
          scrollTrigger: {
            trigger: box,
            start: "top 85%",
            end: "top 50%",
            scrub: 1.5,
          },
        }
      );
    });

    // section 4
    const reviewSection = reviewSectionRef.current;
    if (reviewSection) {
      gsap.fromTo(
        reviewSection,
        { opacity: 0 },
        {
          opacity: 1,
          ease: "power3.inOut",
          scrollTrigger: {
            trigger: reviewSection,
            start: "top 80%",
            toggleActions: "play none none none",
          },
        }
      );
    }

    // section 5

    // Residential Solar Animation
    gsap.fromTo(
      ".residential-solar",
      { x: 0, opacity: 1 },
      {
        x: "-105%",
        opacity: 0,
        duration: 20,
        ease: "power3.out",
        scrollTrigger: {
          trigger: ".residential-solar",
          start: "top 20%",
          end: "top -100%",
          scrub: 6,
        },
      }
    );

    // Industrial Solar Animation
    gsap.fromTo(
      ".industrial-solar",
      { x: "600%", opacity: 1 },
      {
        x: "-100%",
        opacity: 1,
        duration: 40,
        ease: "power3.out",
        scrollTrigger: {
          trigger: ".industrial-solar",
          start: "top 20%",
          end: "top -100%",
          scrub: 6,
        },
      }
    );

    // Solar Park Animation
    gsap.fromTo(
      ".solar-park",
      { x: "1000%", opacity: 0 },
      {
        x: "0%",
        opacity: 1,
        duration: 20,
        ease: "power3.out",
        scrollTrigger: {
          trigger: ".solar-park",
          start: "center center",
          end: "top -100%",
          scrub: 6, // Increased scrub for smoother transition
          onEnter: () => {
            gsap.to(".industrial-solar", { opacity: 0 });
          },
        },
      }
    );

    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  return (
    <>
      <div className="main" style={{ height: "300vh" }}>
        <div
          className="padding-none container-fluid top-0 d-flex flex-column overflow-hidden justify-content-center align-items-center home-container"
          style={{
            position: "sticky", // Changed from sticky to relative
            height: "100vh",
            width: "100%",
          }}
        >
          <div
            className="row justify-content-center overflow-hidden align-items-center"
            style={{ width: "100%", height: "100%" }}
          >
            <div className="col-md-8 text-center">
              {/* Light Image */}
              <img
                className="position-absolute light translate-middle-x img-fluid"
                style={{ top: "-100px", maxWidth: "50%", height: "auto" }}
                src={light}
                alt="Light Background"
              />
              {/* Heading */}
              <h1 className="display-1 mt-5 fw-bold text-center main-heading">
                Think{" "}
                <span
                  style={{
                    background: "linear-gradient(to right, #FF5E43, #FB992E)",
                    WebkitBackgroundClip: "text",
                    color: "transparent",
                  }}
                >
                  Solar
                </span>{" "}
                Think{" "}
                <span
                  style={{
                    background: "linear-gradient(to right, #FF5E43, #FB992E)",
                    WebkitBackgroundClip: "text",
                    color: "transparent",
                  }}
                >
                  Radiant
                </span>
              </h1>
            </div>
            <div className="col-md-6 text-center">
              <img
                className="img-fluid solar-image"
                style={{ width: "100vw", height: "auto" }}
                src={solar}
                alt="Solar Panel"
              />
            </div>
            <div
              className="row justify-content-center"
              style={{ width: "100vw" }}
            >
              <div className="col-12 text-center p-0 m-0 layer-image-container">
                <img
                  className="img-fluid layer-image"
                  style={{
                    width: "100vw",
                    height: "auto",
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                  }}
                  src={layer2}
                  alt="Layer Background"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Section 2 */}
      <div
        style={{
          minHeight: "40vh",
          width: "100%",
          background: "rgb(26,42,63)",
        }}
        className="d-flex justify-content-center align-items-center"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div
              ref={sectionRef}
              className="col-12 col-md-10 col-lg-8 text-center"
            >
              {/* Adjust font size based on screen size */}
              <h1 className="display-4 dispalay-sm-6 display-md-4 display-lg-3 text-white fw-bold mb-1">
                Turn Your Home into a
              </h1>
              <h1 className="display-4 dispalay-sm-6 display-md-4 display-lg-3 display-xxl-2 mb-1">
                <span className="text-white fw-bold">Source of</span>{" "}
                <span
                  style={{
                    background: "linear-gradient(to right, #FF5E43, #FB992E)",
                    WebkitBackgroundClip: "text",
                    color: "transparent",
                  }}
                  className="fw-bold mb-1"
                >
                  Constant Savings
                </span>
              </h1>
              <div className=" fs-5">
                {/* Paragraph responsive font-size */}
                <p className="text-white mb-0">
                  With Radiant solar energy, you not only save money in the long
                  run, but you also invest in a more sustainable future.
                  Although there is an initial investment, solar systems pay for
                  themselves over time, offering substantial savings on your
                  energy bills. Make the smart switch today and reap the
                  financial benefits tomorrow!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Section 3 */}
      <div style={{ height: "100vh", overflowX: "hidden" }}>
        {" "}
        <div
          className="container-fluid p-0 m-0 d-flex flex-column justify-content-center align-items-center"
          style={{ position: "sticky", height: "100%", width: "100%" }}
        >
          <div
            className="position-relative overflow-hidden  "
            style={{ height: "100%", width: "100%" }}
          >
            <img
              src={solars}
              alt="Solar Image"
              style={{
                position: "absolute",

                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                objectFit: "cover",
                overflow: "hidden",
              }}
            />
            <div
              className="why-text position-absolute top-50 start-50  translate-middle text-center"
              style={{ zIndex: 1, width: "100%" }}
            >
              <h1
                ref={textRef}
                className=" why-us  fw-bold text-white display-4 display-md-3 display-lg-1"
                style={{ fontSize: "clamp(2.5rem, 8vw, 10rem)" }}
              >
                Why Choose Us?
              </h1>
            </div>

            {/* Boxes in a row */}
            <div
              id="boxmodals"
              className="d-flex justify-content-center  position-absolute "
              style={{
                top: "70vh",
                left: "50%",
                zIndex: 2,
                transform: "translateX(-50%)",
              }}
            >
              {boxContent.map((box, index) => (
                <div
                  key={index}
                  ref={(el) => (boxesRef.current[index] = el)}
                  className="p-3 rounded eachbox "
                  style={{
                    // height:"auto",
                    width: "290px",
                    background: "linear-gradient(45deg, #FFF8F4, #FEB088)",
                    opacity: 0,
                  }}
                >
                  <h1 className="fs-4 fw-bold mb-2">{box.title}</h1>
                  <p className="fw-bold fs-6">{box.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* section4 */}
      {/* <div
        ref={reviewSectionRef}
        style={{ width: "100vw", opacity: "0" }}
        className="container p-3  "
      >
        <div className="row">
          {reviewItems.map((item, index) => (
            <div key={index} className="col-md-4 text-center">
              <h1
                style={{
                  background: "linear-gradient(to right, #FF5E43, #FB992E)",
                  WebkitBackgroundClip: "text",
                  color: "transparent",
                }}
                className="mb-1 display-1 fw-bold"
              >
                
                <CountUp
                  start={0}
                  end={formatCount(item.count)}
                  duration={4.5} // Slower count animation duration
                  suffix={
                    item.count >= 1000000
                      ? "Lakh+"
                      : item.count >= 1000
                      ? "K+"
                      : "+"
                  }
                  decimals={0}
                  separator=","
                />
           
            </h1>
            <p className="fw-medium fs-4">{item.title}</p>
          </div>
        ))}
        </div>
      </div> */}
      <div
        ref={reviewSectionRef}
        style={{ width: "100%", opacity: "1" }}
        className="container p-3  "
      >
        <div className="row">
          {reviewItems.map((item, index) => (
            <div key={index} className="col-md-4 text-center">
              <h1
                style={{
                  background: "linear-gradient(to right, #FF5E43, #FB992E)",
                  WebkitBackgroundClip: "text",
                  color: "transparent",
                }}
                className="mb-1 display-1 fw-bold"
              >
                {" "}
                <CountUp
                  start={0}
                  end={formatCount(item.count)}
                  duration={2.75}
                  suffix={
                    item.count >= 10000000
                      ? "Crore+"
                      : item.count >= 1000
                      ? "+"
                      : "+"
                  }
                  decimals={0}
                  separator=","
                  enableScrollSpy
                  scrollSpyDelay={100}
                />
              </h1>
              <p className="fw-medium fs-4">{item.title}</p>
            </div>
          ))}
        </div>
      </div>

      {/* section 5 */}

      <div style={{ height: "300vh" }} className="main">
        <div
          style={{
            position: "sticky",
            height: "100vh",
            width: "100%",
          }}
          className="padding-none container-fluid top-0 d-flex flex-column overflow-hidden justify-content-center align-items-center"
        >
          <div
            style={{ height: "100vh", width: "100vw", position: "relative" }}
            className="overflow-hidden position-sticky top-0"
          >
            <div
              style={{ height: "100vh", width: "100vw", objectFit: "cover" }}
              className="position-relative"
            >
              <div
                style={{
                  height: "30%",
                  width: "100%",
                }}
                className="position-relative"
              >
                <img
                  style={{ height: "100%", width: "100%" }}
                  src={sky}
                  alt="sky"
                  className=""
                />
                <div
                  className="position-absolute bottom-0  text-center col-12 col-md-6 residential-solar px-3"
                  style={{
                    marginBottom: "-82px",

                    left: "50%",
                    transform: "translate(-50%, -20%)",
                    width: "100%",
                    zIndex: 2,
                    clipPath: "inset(0 0 25% 0)",
                  }}
                >
                  <h1
                    style={{
                      fontSize: "9rem",
                      color: "#1A2A3F",
                      width: "100%",
                    }}
                    className="fw-bold residential-text"
                  >
                    Residencial Solar
                  </h1>
                </div>

                <div
                  className=" industrial-solar position-absolute bottom-0 text-center col-12 col-md-6  px-3"
                  style={{
                    marginBottom: "-90px",
                    left: "50%",
                    transform: "translate(-50%, -20%)",
                    width: "100%",
                    zIndex: 2,
                    clipPath: "inset(0% 0 25% 0)",
                  }}
                >
                  <h1
                    style={{
                      fontSize: "10rem",
                      color: "#1A2A3F",
                    }}
                    className="fw-bold industrial-text"
                  >
                    Industrial Solar
                  </h1>
                </div>

                <div
                  className="solar-park position-absolute bottom-0 text-center col-12 col-md-6 px-3"
                  style={{
                    marginBottom: "-89px",
                    left: "50%",
                    transform: "translate(-50%, -20%)",
                    width: "100%",
                    zIndex: 2,
                    clipPath: "inset(0% 0 25% 0)",
                  }}
                >
                  <h1
                    style={{
                      fontSize: "10rem",
                      color: "#1A2A3F",
                    }}
                    className="fw-bold solar-text"
                  >
                    Solar Park
                  </h1>
                </div>
              </div>

              <img
                className="object-fit-cover"
                style={{ height: "70%", width: "100%" }}
                src={solarbackground}
                alt="solarbaclground"
              />
            </div>
          </div>
        </div>
      </div>

      {/* section 6  */}
      <div
        style={{ width: "100%", height: "auto" }}
        className="container-fluid py-5"
      >
        <div className="row justify-content-center">
          {/* Our Vision Card */}
          <div className="col-12 col-md-6 mb-4 g-4">
            <div
              className="card "
              style={{
                border: "none",
                borderRadius: "20px",
                background: "linear-gradient(to right, #FFF8F4, #FFD1BA)",
                minHeight: "300px",
                width: "100%",
              }}
            >
              <div className="card-body d-flex flex-column align-items-start">
                <h1 className="fw-bold display-1">Our Vision</h1>
                <p
                  style={{ marginTop: "90px" }}
                  className="mt-auto fs-5 fw-medium"
                >
                  With Radiant solar energy, you not only save money in the long
                  run, but you also invest in a more sustainable future.
                  Although there is an initial investment, solar systems pay for
                  themselves over time, offering substantial savings on your
                  energy bills.
                </p>
              </div>
            </div>
          </div>
          {/* Our Mission Card */}
          <div className="col-12 col-md-6 g-4">
            <div
              className="card  responsive-height"
              style={{
                border: "none",
                borderRadius: "20px",
                background: "linear-gradient(to right, #FFF8F4, #FFD1BA)",
                width: "100%",
                minHeight: "300px",
              }}
            >
              <div className="card-body d-flex flex-column align-items-start">
                <h1 className="fw-bold display-1">Our Mission</h1>
                <p className="mt-auto fs-5 fw-medium">
                  With Radiant solar energy, you not only save money in the long
                  run, but you also invest in a more sustainable future.
                  Although there is an initial investment, solar systems pay for
                  themselves over time, offering substantial savings on your
                  energy bills.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* section 7 */}
      {/* <div
        className="container-fluid mb-1"
        style={{ overflow: "hidden", width: "100%" }}
      >
        <div className="d-flex flex-column align-items-center">
        
          <div className="d-flex align-items-center justify-content-center gap-3">
            <span className="display-1 fw-bold">Our</span>
            <span
              style={{
                background: "linear-gradient(to right, #FF5E43, #FB992E)",
                WebkitBackgroundClip: "text",
                color: "transparent",
              }}
              className="display-1 fw-bold"
            >
              Partner
            </span>
          </div>

        
          <div className="d-flex marquee py-2 ">
            {partners.map((partner) => (
              <img
                key={partner.id}
                src={partner.imgSrc}
                alt={partner.name}
                className="partner-logo img-fluid"
              />
            ))}
          </div>
        </div>
      </div> */}

      {/* section 8 */}
      {/* <section style={{ width: "100%" }} className="p-3">
        <div className="container-fluid">
          <div className="row align-items-center">
        
            <div className="col-12 col-md-6">
              <div
                style={{ lineHeight: "1" }}
                className="display-2 heading fw-bold"
              >
                <span>What</span>
                <div
                  style={{
                    background: "linear-gradient(to right, #FF5E43, #FB992E)",
                    WebkitBackgroundClip: "text",
                    color: "transparent",
                    lineHeight: "1",
                  }}
                >
                  People
                </div>
                <span
                  style={{
                    background: "linear-gradient(to right, #FF5E43, #FB992E)",
                    WebkitBackgroundClip: "text",
                    color: "transparent",
                    lineHeight: "1.2",
                  }}
                >
                  Say
                </span>
              </div>
              <div
                style={{
                  background: "linear-gradient(to right, #FFF8F4, #FFD1BA)",
                  minHeight: "25vh",
                }}
                className="p-3 rounded mt-2 cut-box position-relative testimonial-content"
              >
                <h5 className="fw-bold responsive-title">Amazing Service!!</h5>
                <p className="fw-semibold responsive-text description">
                  {`"${testimonials[currentIndex].text}"`}
                </p>

                <p className="fw-bold responsive-author">
                  {`- ${testimonials[currentIndex].author}`}
                </p>
              </div>
            </div>

      
            <div className="col-12 col-md-6 position-relative testimonial-content">
              <img
                style={{
                  width: "170px",
                  position: "absolute",
                  top: "60px",
                  left: "-150px",
                  zIndex: -1,
                }}
                src={quotes}
                alt="Quotes"
              />
              <img
                src={testimonials[currentIndex].image}
                alt="Solar House"
                className="img-fluid rounded"
              />
            </div>
          </div>

    
          <div className="d-flex justify-content-end mt-3">
            <button
              type="button"
              className="btn rounded-circle d-inline d-md-block"
              onClick={prevTestimonial}
              style={{
                border: "none",
                width: "50px",
                height: "50px",
                background: "linear-gradient(to right, #FF5E43, #FB992E)",
              }}
            >
              <img src={left} alt="Previous" />
            </button>
            <button
              type="button"
              className="btn rounded-circle d-inline  d-md-block ms-2"
              onClick={nextTestimonial}
              style={{
                border: "none",
                width: "50px",
                height: "50px",
                background: "linear-gradient(to right, #FF5E43, #FB992E)",
              }}
            >
              <img src={right} alt="Next" />
            </button>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default Home;
