import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Header from './componets/Header'
import Footer from './componets/Footer'
import Home from './pages/Home'
import ScrollToTop from './componets/ScrollToTop';
import Contact from './pages/Contact';

import About from '../src/pages/About';
import OurProducts from './pages/OurProducts'
import Projects from './pages/Projects'
import HomeHero from './componets/HomeHero';
import GetQuote from './componets/GetQuote';
import SecondStep from './componets/SecondStep';
import FourStep from './componets/FourStep';
import ThirdStep from './componets/ThirdStep';
import Inquiry from './pages/Inquiry';

const App = () => {

  return (
    <>
      {/* <GetQuote/> */}
      {/* <SecondStep/> */}
      {/* <FourStep/> */}
      {/* <ThirdStep/> */}

      <Router future={{ v7_relativeSplatPath: true }}>
        <Header />
        <div style={{ paddingTop: "69px" }} className=''>


          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/products" element={<OurProducts />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/get-quote" element={<GetQuote />} />
            <Route path="/inquiry" element={<Inquiry />} />




          </Routes>
          <ScrollToTop />

          <Footer />

        </div>
      </Router>
    </>
  )
}

export default App