// import React, { useState } from "react";
// import solar from "../assets/Getquotes/solar.png"; // Replace with actual image imports if needed

// const SecondStep = () => {
//   const [selectedKW, setSelectedKW] = useState(null);

//   const kwOptions = [
//     "1.5 Kilowatt",
//     "3 Kilowatt",
//     "4.5 Kilowatt",
//     "6 Kilowatt",
//     "7.5 Kilowatt",
//     "9 Kilowatt",
//     "10.5 Kilowatt",
//     "12 Kilowatt",
//     "13.5 Kilowatt",
//     "15 Kilowatt",
//   ];

//   const getImagesForBox = (numberOfImages) => {
//     const images = [];
//     for (let i = 0; i < numberOfImages; i++) {
//       images.push(solar);
//     }
//     return images;
//   };

//   return (
//     <div style={{ minHeight: "78vh" }} className="px-3">
//       <h2
//         style={{
//           background: "linear-gradient(to right, #FF5E43, #FB992E)",
//           WebkitBackgroundClip: "text",
//           color: "transparent",
//         }}
//         className="fs-1 fw-bold"
//       >
//         Step 2
//       </h2>
//       <p className="display-5 fw-bold">How Much KW you want?</p>

//       <div
//         className="mt-3"
//         style={{
//           display: "grid",
//           gridTemplateColumns: "repeat(5, 1fr)",
//           gap: "50px",
//         }}
//       >
//         {kwOptions.map((option, index) => {
//           const numberOfImages = index + 1;
//           const images = getImagesForBox(numberOfImages);

//           return (

//             <div
//               key={index}
//               onClick={() => setSelectedKW(option)}
//               style={{
//                 borderColor: selectedKW === option ? "rgb(253,154,75)" : "gray",
//                 borderWidth: "2px",
//                 borderStyle: "solid",
//                 backgroundColor: selectedKW === option ? "#FFF9F5" : "white",
//                 cursor: "pointer",
//                 width: "100%",
//                 position: "relative",
//                 height: "150px", // Box height to ensure consistent size
//                 display: "flex",
//                 justifyContent: "center",
//                 alignItems: "center",
//               }}
//               className="rounded-3"
//             >
//               <span
//                 className="fs-6"
//                 style={{
//                   position: "absolute",
//                   top: "-15px",
//                   left: "50%",
//                   transform: "translateX(-50%)",
//                   background: "rgb(253,154,75)",
//                   color: "white",
//                   fontWeight: "bold",
//                   borderRadius: "20px",
//                   padding: "1px 5px",
//                   zIndex: 1,
//                 }}
//               >
//                 {option}
//               </span>

//               <div
//                 style={{
//                   display: "grid",
//                   gridTemplateRows: "repeat(2, 1fr)", // Two rows
//                   gridTemplateColumns: "repeat(5, 1fr)", // Five columns per row
//                   gap: "5px", // Space between images
//                   width: "90%",
//                   height: "90%",
//                 }}
//               >
//                 {[...Array(10)].map((_, imgIndex) => (
//                   <div
//                     key={imgIndex}
//                     style={{
//                       background:
//                         imgIndex < numberOfImages
//                           ? `url(${solar})`
//                           : "transparent",
//                       backgroundSize: "contain",
//                       backgroundRepeat: "no-repeat",
//                       backgroundPosition: "center",
//                       border: imgIndex < numberOfImages ? " lightgray" : "none",
//                     }}
//                   ></div>
//                 ))}
//               </div>
//             </div>
//           );
//         })}
//       </div>
//     </div>
//   );
// };

// export default SecondStep;

import React, { useState } from "react";
import solar from "../assets/Getquotes/solar.png";
import "./secondstep.css";

const SecondStep = ({nextButtonStatus, SetNextButtonStatus}) => {
  const [selectedKW, setSelectedKW] = useState(null);
  

  const kwOptions = [
    "1.5 Kilowatt",
    "3 Kilowatt",
    "4.5 Kilowatt",
    "6 Kilowatt",
    "7.5 Kilowatt",
    "9 Kilowatt",
    "10.5 Kilowatt",
    "12 Kilowatt",
    "13.5 Kilowatt",
    "15 Kilowatt",
  ];

  // const getImagesForBox = (numberOfImages) => {
  //   const images = [];
  //   for (let i = 0; i < numberOfImages; i++) {
  //     images.push(solar);
  //   }
  //   return images;
  // };

  return (
    <div style={{ height: "78vh" }} className="px-3 boxes ">
      <h2
        style={{
          background: "linear-gradient(to right, #FF5E43, #FB992E)",
          WebkitBackgroundClip: "text",
          color: "transparent",
        }}
        className="fs-1 fw-bold"
      >
        Step 2
      </h2>
      <p className="display-5 fw-bold">How Much KW you want?</p>

      <div
        className="d-grid gap-4  kw-options-grid"
        style={{
          gridTemplateColumns: "repeat(5, 1fr)", // Define 5 boxes per row
          gap: "20px", // Adjust spacing between boxes
        }}
      >
        {kwOptions.map((option, index) => {
          const numberOfImages = index + 1;

          return (
            <div
              key={index}
              // onClick={() => (setSelectedKW(option))}
              onClick={()=>{
                setSelectedKW(option);
                SetNextButtonStatus(true);
              }}
              style={{
                borderColor: selectedKW === option ? "rgb(253,154,75)" : "gray",
                borderWidth: "2px",

                borderStyle: "solid",
                backgroundColor: selectedKW === option ? "#FFF9F5" : "white",
                cursor: "pointer",
                width: "100%",
                position: "relative",
                height: "150px", // Box height to ensure consistent size
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="rounded-3  "
            >
              <span
                className="fs-6 text "
                style={{
                  position: "absolute",
                  display: "flex",
                  justifyContent: "center",
                  width: "100px",
                  top: "-18px",
                  left: "60%",
                  transform: "translateX(-50%)",
                  background: "rgb(253,154,75)",
                  color: "white",
                  fontWeight: "bold",
                  borderRadius: "20px",
                  padding: "1px 5px",
                  zIndex: 1,
                }}
              >
                {option}
              </span>

              <div
                className="mt-3"
                style={{
                  display: "grid",
                  gridTemplateRows: "repeat(2, 1fr)", // Two rows
                  gridTemplateColumns: "repeat(5, 1fr)", // Five columns per row
                  gap: "5px", // Space between images
                  width: "90%",
                  height: "90%",
                }}
              >
                {[...Array(10)].map((_, imgIndex) => (
                  <div
                    key={imgIndex}
                    style={{
                      background:
                        imgIndex < numberOfImages
                          ? `url(${solar})`
                          : "transparent",
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      border: imgIndex < numberOfImages ? "lightgray" : "none",
                    }}
                  ></div>
                ))}
              </div>
            </div>
            
          );
        })}
      </div>
   
    </div>
  );
};

export default SecondStep;
