import React, { useEffect } from "react";

import solartable from "../assets/About/solartable.png";
import texture from "../assets/About/Texture.png";
import panel from "../assets/About/Panel.png";
import aps from "../assets/aps.png";
import havells from "../assets/havells.png";
import deye from "../assets/deye.png";
import PratikSir from "../assets/About/Pratik Sir.png";
import SanketSir from "../assets/About/Sanket Sir.png";
import "./about.css";
import AOS from "aos";
import "aos/dist/aos.css";

const partners = [
  { id: "1", name: "APS", imgSrc: aps },

  { id: "2", name: " Havells", imgSrc: havells },

  { id: "3", name: "Deye", imgSrc: deye },
];
const About = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <>
      {/* section 1 */}
      <div
        style={{ height: "90vh", width: "100%" }}
        className="main-box px-4 py-5 position-relative container-fluid "
      >
        {/* Outer Container */}
        <div
          style={{
            height: "90%",
            width: "100%",
            background: "linear-gradient(to right, #FF5E43, #FB992E)",
          }}
          className="rounded-5"
        >
          <div className="container position-relative">
            {/* Heading Section */}
            <div className="row text-center heading-section">
              <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                <h1 className="fw-bold text-white display-4 display-md-3 display-lg-1">
                  About Radiant Sun Energy
                </h1>
                <p className="text-white fs-5 fw-semibold">
                  We, Radiant Sun Energy LLP in Anand, Gujarat, are a leading
                  dealer of solar energy systems and other products. We design
                  and manufacture high quality, aesthetically pleasing devices
                  aimed at enhancing your outdoor, off-grid experience. We deal
                  in environmentally safe products such as solar roofs, solar
                  sheets, solar geyser etc. Our aim is to provide the best
                  products to our customers through excellent service.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Image Section */}
        <div
          className="position-absolute img-section"
          style={{
            height: "100%",
            width: "100%",
            bottom: "-60px",
            left: 0,
            right: 0,
          }}
        >
          {/* For small devices */}
          <img
            className="position-absolute img-fluid d-block d-md-none mx-auto"
            src={solartable}
            alt="Solar Table"
            style={{
              bottom: "15%",
              right: "0%",
              height: "auto",
              width: "auto",

              // objectFit: "contain",
            }}
          />

          {/* Image to be hidden on small devices */}
          <img
            style={{ height: "100%", width: "100%" }}
            className="img-fluid d-none d-md-block"
            src={solartable}
            alt=""
          />
        </div>
      </div>

      {/* Section 2 */}
      <div
        data-aos="fade-up "
        className="growth p-3 py-5 "
        style={{ width: "100%", minHeight: "70vh" }}
      >
        <div
          className="border rounded-5 overflow-hidden position-relative"
          style={{ height: "100%", width: "100%", background: "rgb(25,42,64)" }}
        >
          {/* Background Image */}
          <img
            className="img-fluid h-100 w-100 object-cover"
            src={texture}
            alt="Background Texture"
            style={{
              objectFit: "cover",
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: "1",
            }}
          />

          {/* Text Content */}
          <div className="position-relative ms-md-5  p-3" style={{ zIndex: 1 }}>
            {/* Title */}
            <h1 className="fw-bold display-4 display-md-4 col-12 text-white">
              <span>Core Pillars of</span>{" "}
              <span
                style={{
                  background: "linear-gradient(to right, #FF5E43, #FB992E)",
                  WebkitBackgroundClip: "text",
                  color: "transparent",
                }}
              >
                Growth and Innovation
              </span>
            </h1>

            {/* Details Section */}
            <div className="details py-3">
              {/* First Pillar */}
              <div className="d-flex  flex-md-row align-items-start">
                <p
                  className="fs-4 me-3 fw-bold me-md-3 text-center text-md-start"
                  style={{
                    background: "linear-gradient(to right, #FF5E43, #FB992E)",
                    WebkitBackgroundClip: "text",
                    color: "transparent",
                  }}
                >
                  1.{" "}
                </p>
                <div>
                  <p
                    className="fs-4 fw-bold mb-1 "
                    style={{
                      background: "linear-gradient(to right, #FF5E43, #FB992E)",
                      WebkitBackgroundClip: "text",
                      color: "transparent",
                    }}
                  >
                    Open & Honest Communication
                  </p>
                  <p className="text-white fs-5">
                    We are ethical, genuine, and fair. This is reflected in our{" "}
                    <br />
                    services and products.
                  </p>
                </div>
              </div>

              {/* Second Pillar */}
              <div className="d-flex flex-md-row align-items-start">
                <p
                  className="fs-4 me-3 fw-bold me-md-3 text-center text-md-start"
                  style={{
                    background: "linear-gradient(to right, #FF5E43, #FB992E)",
                    WebkitBackgroundClip: "text",
                    color: "transparent",
                  }}
                >
                  2.
                </p>
                <div>
                  <p
                    className="fs-4 fw-bold mb-1"
                    style={{
                      background: "linear-gradient(to right, #FF5E43, #FB992E)",
                      WebkitBackgroundClip: "text",
                      color: "transparent",
                    }}
                  >
                    Research & Development
                  </p>
                  <p className="text-white fs-5">
                    We stay accurate about the world we are building and
                    continuously <br />
                    upgrade ourselves, including the technology around us.
                  </p>
                </div>
              </div>

              {/* Third Pillar */}
              <div className="d-flex flex-md-row align-items-start">
                <p
                  className="fs-4 me-3 fw-bold me-md-3 text-center text-md-start"
                  style={{
                    background: "linear-gradient(to right, #FF5E43, #FB992E)",
                    WebkitBackgroundClip: "text",
                    color: "transparent",
                  }}
                >
                  3.
                </p>
                <div>
                  <p
                    className="fs-4 fw-bold mb-1"
                    style={{
                      background: "linear-gradient(to right, #FF5E43, #FB992E)",
                      WebkitBackgroundClip: "text",
                      color: "transparent",
                    }}
                  >
                    Careers & Team
                  </p>
                  <p className="text-white fs-5">
                    Certain circumstances and owing to the claims of duty
                    obligations of <br /> business will frequently occur.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Decorative Image for Larger Screens */}
          <img
            className="position-absolute img-fluid d-none d-md-block"
            src={panel}
            alt="Solar Panel"
            style={{
              bottom: "0%",
              right: "1%",
              width: "45%",
              zIndex: 0,
              objectFit: "contain",
            }}
          />

          {/* Decorative Image for Small Screens */}
          <img
            className="d-block d-md-none mt-3 mx-auto img-fluid"
            src={panel}
            alt="Solar Panel"
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      </div>

      {/* section 3 */}
      <div
        data-aos="zoom-in"
        style={{ width: "100%", maxHeight: "70vh" }}
        className="p-2"
      >
        <div className="container">
          <div className="founder display-3  col-11 fw-bold d-flex justify-content-center">
            <p> Meet the </p> &nbsp;
            <span
              style={{
                background: "linear-gradient(to right, #FF5E43, #FB992E)",
                WebkitBackgroundClip: "text",
                color: "transparent",
              }}
            >
              Founder
            </span>
          </div>
          <div className="desc text-center">
            <p className="fw-semibold fs-5">
              Radiant Sun Energy LLP was founded by two exceptionally talented
              individuals with extensive industry experience. Their experience
              and distinctive skill sets give a competitive advantage to the
              company. With their guidance, the organization has become one of
              the fastest-growing companies in the solar industry.
            </p>
          </div>
        </div>
      </div>

      {/* section 4 */}
      <div data-aos="fade-up" style={{ width: "100%" }} className=" py-3">
        {/* Card 1 */}

        <div className="d-flex justify-content-end">
          <div
            className="row align-items-center py-4 p-4"
            style={{
              background: "rgb(255,233,222)",
              borderRadius: "15px",
              marginBottom: "80px",
              width: "90%",
            }}
          >
            {/* Image Section */}
            {/* <div className="col-md-4 col-12 text-center p-3 ">
              <img
                src={SanketSir}
                alt="Sunny Patel"
                className="img-fluid image1"
              />
            </div> */}

            {/* Text Section */}
            <div className="col-md-12 col-12 order-0 order-md-1">
              <h3
                style={{
                  background: "linear-gradient(to right, #FF5E43, #FB992E)",
                  WebkitBackgroundClip: "text",
                  color: "transparent",
                }}
                className="fw-bold display-3"
              >
                Slesh Patel
              </h3>
              <h5 className="fw-bold display-5">
                Founder &amp; Managing Director
              </h5>
              <p className="fw-semibold fs-5">
                Slesh Patel is the visionary founder of Radiant Sun Energy LLP,
                a leading renewable energy solutions provider. With a deep
                passion for sustainable development and clean energy, Slesh has
                been instrumental in promoting innovative solar power solutions
                to address modern energy challenges. His expertise in renewable
                energy technologies, coupled with a strategic business acumen,
                has positioned Radiant Sun Energy as a trusted partner for eco-
                friendly energy projects.
                <br /> Under his leadership, the company has successfully
                executed numerous solar power installations, contributing
                significantly to reducing carbon footprints and driving the
                adoption of green energy across various sectors. Slesh&#39;s
                commitment to sustainability and his forward-thinking approach
                make him a key influencer in India&#39;s renewable energy
                landscape.
              </p>
            </div>
          </div>
        </div>

        {/* Card 2 */}
        <div
          data-aos="fade-up"
          className="row align-items-center flex-row-reverse  p-4"
          style={{
            background: "rgb(255,233,222)",
            borderRadius: "15px",
            marginBottom: "20px",
            width: "90%",
          }}
        >
          {/* <div className="col-md-4 text-center">
            <img
              src={PratikSir}
              alt="Pratik Thumbar"
              className=" img-fluid image2"
            />
          </div> */}

          <div className="col-md-12">
            <h3
              style={{
                background: "linear-gradient(to right, #FF5E43, #FB992E)",
                WebkitBackgroundClip: "text",
                color: "transparent",
              }}
              className=" fw-bold display-3"
            >
              Jankiben Chauhan
            </h3>
            <h5 className="fw-bold display-4">Director</h5>
            <p className="fs-5 fw-semibold">
              Jankiben Chauhan, Director of Radiant Sun Energy LLP, is a
              passionate advocate for sustainable development. Her expertise in
              renewable energy and strategic leadership has made Radiant Sun
              Energy a trusted provider of innovative solar solutions,
              contributing to a greener future.
            </p>
          </div>
        </div>
        <div
          data-aos="zoom-in"
          className="d-flex flex-column align-items-center"
        >
          <div className="d-flex align-items-center justify-content-center gap-3">
            <span className="display-3 fw-bold">Authorized Distributor</span>
            <span
              style={{
                background: "linear-gradient(to right, #FF5E43, #FB992E)",
                WebkitBackgroundClip: "text",
                color: "transparent",
              }}
              className="display-3 fw-bold"
            >
              and Reseller
            </span>
          </div>

          <div className="d-flex gap-5 overflow-x-auto  py-4 ">
            {partners.map((partner) => (
              <img
                key={partner.id}
                src={partner.imgSrc}
                alt={partner.name}
                className="partner-logo img-fluid"
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
