import React, { useRef} from "react";
import './fourstep.css';

const FourStep = ({nextButtonStatus, SetNextButtonStatus}) => {
  const boxesRef = useRef(null);
  const items = [
    {
      title: "SUGGESTED CAPACITY (kW)",
      data: "5",
      imgSrc:
        "https://goldisolar.com/wp-content/themes/altitude-pro/img/solar-calculator/s1.png",
    },
    {
      title: "MONTHLY SAVING (kWh)",
      imgSrc:
        "https://goldisolar.com/wp-content/themes/altitude-pro/img/solar-calculator/monthly-saving-kwh.jpg",
    },

    {
      title: "MONTHLY SAVING (INR)",
      imgSrc:
        "https://goldisolar.com/wp-content/themes/altitude-pro/img/solar-calculator/monthly-saving.jpg",
    },
    {
      title: "REQUIRED SPACE (SQFT)",
      imgSrc:
        "https://goldisolar.com/wp-content/themes/altitude-pro/img/solar-calculator/required-space.jpg",
    },
    {
      title: "CO₂ EMISSION REDUCTION PER YEAR",
      imgSrc:
        "https://goldisolar.com/wp-content/themes/altitude-pro/img/solar-calculator/co2.jpg",
    },
  ];

  return (
    <div className="step4">
      <h2
        style={{
          background: "linear-gradient(to right, #FF5E43, #FB992E)",
          WebkitBackgroundClip: "text",
          color: "transparent",
        }}
        className="fs-1 fw-bold "
      >
        Step 4
      </h2>
      <p className="display-5 fw-bold ">Prime Equipment:</p>

      <div ref={boxesRef} id="boxesRef" className="p-3   my-5">
        <div className=" row   ">
          {items.map((item, index) => (
            <div className="col-md-4 py-3   px-3  " key={index}>
              <div className="card border    shadow-sm">
                <div className="card-body   fw-bold">
                  <h5 className="card-title fw-bold">{item.title}</h5>
                  {/* <h1 className="">{item.data}</h1> */}
                </div>
                <img
                  src={item.imgSrc}
                  className="card-img-top img-fluid "
                  alt={item.title}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FourStep;
