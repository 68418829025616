import React, { useRef } from "react";
import "./thirdstep.css";

const ThirdStep = ({nextButtonStatus, SetNextButtonStatus}) => {
  const boxesRef = useRef(null);

  const handleScroll = () => {
    boxesRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <div style={{}} className="">
      {/* <div
      style={{
        borderColor: "rgb(253,154,75)",
        borderWidth: "2px",
        borderStyle: "solid",
        backgroundColor: "rgb(255,249,245)",
      }}
      className="main rounded-5 p-4"
    > */}
      <h2
        style={{
          background: "linear-gradient(to right, #FF5E43, #FB992E)",
          WebkitBackgroundClip: "text",
          color: "transparent",
        }}
        className="fs-1 fw-bold "
      >
        Step 3
      </h2>
      <p className="display-5 fw-bold ">Roof Sizes:</p>

      {/* <div className="radio-group d-flex gap-3 p-2 rounded flex-wrap">
        <div className="mt-2  bg-white   border p-2 rounded">
          <input
            style={{
              borderColor: "rgb(253,154,75)",
              borderWidth: "2px",
              borderStyle: "solid",
            }}
            type="radio"
            id="0-5"
            name="ageRange"
            value="0-5"
            className="form-check-input "
          />
          <label htmlFor="0-5" className="ms-2 fw-bold">
            0-5 years
          </label>
        </div>
        <div className="mt-2 bg-white border p-2 rounded">
          <input
            style={{
              borderColor: "rgb(253,154,75)",
              borderWidth: "2px",
              borderStyle: "solid",
            }}
            type="radio"
            id="6-10"
            name="ageRange"
            value="6-10"
            className="form-check-input"
          />
          <label htmlFor="6-10" className="ms-2 fw-bold">
            6-10 years
          </label>
        </div>
        <div className="mt-2 border p-2  bg-white rounded">
          <input
            style={{
              borderColor: "rgb(253,154,75)",
              borderWidth: "2px",
              borderStyle: "solid",
            }}
            type="radio"
            id="11-20"
            name="ageRange"
            value="11-20"
            className="form-check-input"
          />
          <label htmlFor="11-20" className="ms-2 fw-bold">
            11-20 years
          </label>
        </div>
        <div className="mt-2 border p-2  bg-white rounded">
          <input
            style={{
              borderColor: "rgb(253,154,75)",
              borderWidth: "2px",
              borderStyle: "solid",
            }}
            type="radio"
            id="20+"
            name="ageRange"
            value="20+"
            className="form-check-input"
          />
          <label htmlFor="20+" className="ms-2 fw-bold">
            20+ years
          </label>
        </div>
      </div> */}

      <div className="mt-2">
        <p className="display-6 fw-bold">
          Estimated Roof Area Available for Solar Panels (sq ft):
        </p>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            border: "1px solid #ccc",
            borderRadius: "5px",
            padding: "10px",
            width: "100%",
            maxWidth: "500px", // स्क्रीन के बड़े आकार के लिए अधिकतम चौड़ाई
            background: "white",
            boxSizing: "border-box", // padding को शामिल करने के लिए
          }}
        >
          <input
            type="text"
            placeholder="YXZ"
            style={{
              border: "none",
              outline: "none",
              flex: 1,
              fontSize: "16px",
              fontWeight: "bold",
            }}
          />
          <span style={{ fontSize: "16px", fontWeight: "bold" }}>sq ft</span>
        </div>
      </div>

      {/* Solar Calculator Section */}
      <div className="container-fluid">
        <h2 className="text-center mb-4 fs-1 fw-semibold">Solar Calculator</h2>
        <div className="properties-searchbar border solarCalculator bg-white p-4 rounded">
          <form id="solarcal">
            <div className="row ">
              {/* State Dropdown */}
              <div className="col-12 col px-3 -sm-6 col-md-4 col-lg-3">
                <label htmlFor="state" className="form-label fw-bold fs-5">
                  Select State
                </label>
                <select
                  id="state"
                  name="state"
                  className="form-select fw-semibold custom-select"
                  defaultValue=""
                >
                  <option className="fw-bold " value="" disabled>
                    Select State
                  </option>
                  <option className="fw-bold" value="5.1">
                    Andaman and Nicobar
                  </option>
                  <option className="fw-bold" value="7.3">
                    Andhra Pradesh
                  </option>
                  <option className="fw-bold" value="4.9">
                    Bihar
                  </option>
                  <option className="fw-bold" value="2.2">
                    Chhattisgarh
                  </option>
                  <option className="fw-bold" value="2.5">
                    Dadra and Nagar Haveli
                  </option>
                  <option className="fw-bold" value="2.5">
                    Daman and Diu
                  </option>
                  <option className="fw-bold" value="3.7">
                    Goa
                  </option>
                  <option className="fw-bold" value="6.5">
                    Gujarat
                  </option>
                  <option className="fw-bold" value="5.6">
                    Haryana
                  </option>
                  <option className="fw-bold" value="4">
                    Himachal Pradesh
                  </option>
                  <option className="fw-bold" value="3.3">
                    Jammu and Kashmir
                  </option>
                  <option className="fw-bold" value="6.2">
                    Karnataka
                  </option>
                  <option className="fw-bold" value="7.5">
                    Kerala
                  </option>
                  <option className="fw-bold" value="4">
                    Lakshadweep
                  </option>
                  <option className="fw-bold" value="6.8">
                    Madhya Pradesh
                  </option>
                  <option className="fw-bold" value="8.8">
                    Maharashtra
                  </option>
                  <option className="fw-bold" value="4.1">
                    Meghalaya
                  </option>
                  <option className="fw-bold" value="6.4">
                    Delhi
                  </option>
                  <option className="fw-bold" value="5.3">
                    Odisha
                  </option>
                  <option className="fw-bold" value="2.4">
                    Puducherry
                  </option>
                  <option className="fw-bold" value="5.8">
                    Punjab
                  </option>
                  <option className="fw-bold" value="5.3">
                    Rajasthan
                  </option>
                  <option className="fw-bold" value="5.8">
                    Tamil Nadu
                  </option>
                  <option className="fw-bold" value="5.4">
                    Tripura
                  </option>
                  <option className="fw-bold" value="3.8">
                    Uttar Pradesh
                  </option>
                  <option className="fw-bold" value="3.1">
                    Uttarakhand
                  </option>
                  <option className="fw-bold" value="8">
                    West Bengal
                  </option>
                </select>
              </div>

              {/* Category Dropdown */}
              <div className="col-12 col-sm-6  px-3 col-md-4 col-lg-3">
                <label htmlFor="category" className="form-label fs-5 fw-bold">
                  Select Category
                </label>
                <select
                  id="category"
                  name="category"
                  className="form-select fw-bold"
                  defaultValue=""
                >
                  <option className="fw-bold" value="" disabled>
                    Select Category
                  </option>
                  <option className="fw-semibold" value="residential">
                    Residential
                  </option>
                  <option className="fw-semibold" value="industrial">
                    Industrial
                  </option>
                </select>
              </div>

              {/* Monthly Electricity Bill */}
              <div className="col-12 col-sm-6 px-3 col-md-4 col-lg-3">
                <label
                  htmlFor="electricity"
                  className="form-label fw-bold fs-5"
                >
                  Monthly Electricity Bill
                </label>
                <input
                  type="number"
                  id="electricity"
                  name="electricity"
                  className="form-control fw-bold"
                  placeholder="Enter amount"
                />
              </div>

              {/* Submit Button */}
              <div className="col-12 col-sm-6 col-md-4 col-lg-3 d-flex justify-content-center">
                <button
                  onClick={handleScroll}
                  id="calculateButton"
                  type="button"
                  className=" fw-bold mt-4 fs-5 bg-primary text-white"
                >
                  Calculate{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-arrow-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* <div ref={boxesRef} id="boxesRef" className="p-3   my-5">
        <div className=" row   ">
          {items.map((item, index) => (
            <div className="col-md-4 py-3   px-3  " key={index}>
              <div className="card border    shadow-sm">
                <div className="card-body   fw-bold">
                  <h5 className="card-title fw-bold">{item.title}</h5>
                </div>
                <img
                  src={item.imgSrc}
                  className="card-img-top img-fluid "
                  alt={item.title}
                />
              </div>
            </div>
          ))}
        </div>
      </div> */}

      {/* <div className="text-end mt-4  ">
      <button
        style={{
          background: "linear-gradient(to right, #FF5E43,#FB992E)",
        }}
        className="btn px-4 py-2 text-white fw-bold rounded-5"
      >
        Next
      </button>
    </div> */}
    </div>
  );
};

export default ThirdStep;
