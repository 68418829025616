import React from "react";
import img from "../assets/Contact/img.png";
import texture from "../assets/Contact/Texture.png";
import "./contact.css";

const Contact = () => {
  return (
    <>
      <div
        style={{ height: "100vh", width: "100%" }}
        className="container-fluid main p-4 py-5"
      >
        <div
          style={{ height: "100%", width: "100%" }}
          className=" rounded-5 position-relative overflow-hidden"
        >
          {/* First Image */}
          <div className="position-absolute top-0 start-0 w-100 h-100">
            <img
              className="img-fluid w-100 h-100 object-fit-cover"
              src={img}
              alt="Background"
            />
          </div>

          {/* Second Image (Texture) */}
          <div className="position-absolute top-0 start-0 w-100 h-100">
            <img
              className="img-fluid w-100 h-100 object-fit-cover"
              src={texture}
              alt="Texture"
            />
          </div>

          {/* Heading */}
          <div className="position-absolute col-12 top-0 start-0 w-100 h-100 d-flex justify-content-center">
            <h1 className=" contact text-center text-white fw-bold display-3 col-12">
              Contact Us
            </h1>
          </div>

          {/* Form and Address Section */}
          <div
            style={{ width: "100%" }}
            className=" main-form position-absolute py-5 mt-5  p-3"
          >
            <div className="row  p-1">
              {/* Address Section */}
              <div className="col-md-4 col-12 text-white d-flex flex-column mb-4">
                <div>
                  <h5 className="fw-bold fs-3">Address</h5>
                  <p className="fs-5">
                    405, RADHA SWAMI SAMYAK, nr. JK LIGHT, <br /> Patel Chokdi,
                    Lambhvel Rd,
                    <br /> Anand, 388001, Gujarat, India
                  </p>
                  <h5 className="fw-bold fs-3 ">Contact No.</h5>
                  <p className="fs-5  ">
                    <a
                      href="tel:+918154000349"
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      +91 8154000349
                    </a>
                    , <br />
                    <a
                      href="tel:+912692350300"
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      02692 350300
                    </a>
                  </p>
                  <h5 className="fw-bold fs-3">Email</h5>
                  <p className="fs-5">
                    info@radiantsunenergy.com
                    <br />
                    hr@radiantsunenergy.com
                  </p>
                </div>
              </div>

              {/* Form Section */}
              <div className="col-md-8 col-12">
                <div className="bg-dark bg-opacity-50 rounded-4 p-md-4 p-2">
                  <form>
                    {/* Name Fields */}
                    <div className=" name d-flex gap-2 mb-3">
                      <div className="col-12 col-md-6 mb-3 mb-md-0">
                        <input
                          type="text"
                          className="form-control fw-semibold fs-5"
                          placeholder="First Name"
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <input
                          type="text"
                          className="form-control fw-semibold fs-5"
                          placeholder="Last Name"
                        />
                      </div>
                    </div>

                    {/* Email and Phone Fields */}
                    <div className="name d-flex gap-2  mb-4">
                      <div className="col-12 col-md-6 mb-3 mb-md-0">
                        <input
                          type="email"
                          className="form-control fw-semibold fs-5"
                          placeholder="Email Address"
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <input
                          type="text"
                          className="form-control fw-semibold fs-5"
                          placeholder="Phone No."
                        />
                      </div>
                    </div>

                    {/* Message Field */}
                    <div className="mb-4">
                      <textarea
                        className="form-control fw-semibold fs-4"
                        rows="4"
                        placeholder="Message"
                      ></textarea>
                    </div>

                    {/* Submit Button */}
                    <div>
                      <button
                        type="submit"
                        className="text-white fw-bold fs-5 border-0 py-2 px-5 rounded-pill"
                        style={{
                          background:
                            "linear-gradient(to right, #FF5E43, #FB992E)",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div
        style={{ height: "90vh", width: "100%" }}
        className="container-fluid p-4"
      >
        <div
          style={{ height: "100", width: "100%" }}
          className="rounded-5 position-relative overflow-hidden"
        >
       
          <div className="position-absolute top-0 start-0 w-100 h-100">
            <img
              className="img-fluid w-100 h-100 object-fit-cover"
              src={img}
              alt="Background"
            />
          </div>

          <div className="position-absolute top-0 start-0 w-100 h-100">
            <img
              className="img-fluid w-100 h-100 object-fit-cover"
              src={texture}
              alt="Texture"
            />
          </div>

   
          <div className="position-absolute col-12 top-0 start-0 w-100 h-100 d-flex  justify-content-center">
            <h1 className="text-center text-white fw-bold display-3 col-12">
              Contact Us
            </h1>
          </div>

         
          <div style={{ width: "100%" }} className="position-absolute mt-4 p-4">
            <div className="row mt-4 p-3">
          
              <div className="col-md-4 col-12 text-white d-flex flex-column mb-4">
                <div>
                  <h5 className="fw-bold fs-3">Address</h5>
                  <p className="fs-5">
                    123, ABC Square, DEF Road,
                    <br /> GHI Area, City - Pincode
                  </p>
                  <h5 className="fw-bold fs-3">Contact No.</h5>
                  <p className="fs-5">
                    +91 98765 43210
                    <br />
                    +91 98765 43210
                  </p>
                  <h5 className="fw-bold fs-3">Email</h5>
                  <p className="fs-5">
                    info@radiantsunenergy.com
                    <br />
                    hr@radiantsunenergy.com
                  </p>
                </div>
              </div>

             
              <div className="bg-dark bg-opacity-50 rounded-4 col-md-8 col-12 p-4">
                <form>
                 
                  <div style={{ width: "100%" }} className="d-flex gap-5 mb-3">
                    <div style={{ width: "46%" }} className=" mb-3 mb-md-0">
                      <input
                        type="text"
                        className="form-control fw-semibold fs-5"
                        placeholder="First Name"
                      />
                    </div>
                    <div style={{ width: "46%" }} className="">
                      <input
                        type="text"
                        className="form-control fw-semibold fs-5"
                        placeholder="Last Name"
                      />
                    </div>
                  </div>

                 
                  <div style={{ width: "100%" }} className="d-flex gap-5 mb-4">
                    <div style={{ width: "46%" }} className=" mb-3 mb-md-0">
                      <input
                        type="email"
                        className="form-control fw-semibold fs-5"
                        placeholder="Email Address"
                      />
                    </div>
                    <div style={{ width: "46%" }} className="">
                      <input
                        type="text"
                        className="form-control fw-semibold fs-5"
                        placeholder="Phone No."
                      />
                    </div>
                  </div>

              
                  <div className="mb-4">
                    <textarea
                      className="form-control fw-semibold fs-4"
                      rows="4"
                      placeholder="Message"
                    ></textarea>
                  </div>

                  <div className="">
                    <button
                      type="submit"
                      className="text-white fw-bold fs-5 border-0 py-2 px-5 rounded-pill"
                      style={{
                        background:
                          "linear-gradient(to right, #FF5E43, #FB992E)",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Contact;
