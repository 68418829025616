import React, { useState } from "react";
import "./getquote.css";
import house from "../assets/Getquotes/house.png";
import industrial from "../assets/Getquotes/industrial.png";

import SecondStep from "./SecondStep";
import ThirdStep from "./ThirdStep";
import FourStep from "./FourStep";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const GetQuote = () => {

  
  const navigate = useNavigate();
  // State to track the current step

  const [currentStep, setCurrentStep] = useState(1);
  const [selectedOption, setSelectedOption] = useState(null);
  const [nextButtonStatus, SetNextButtonStatus] = useState(false);

  const handleNext = () => {
    if (currentStep < 4) {
      setCurrentStep(currentStep + 1);
    }
    SetNextButtonStatus(!nextButtonStatus);
    if (currentStep === 4) {
      Swal.fire({
        title: "Successfully Submitted!",
        html: "<p style='font-weight: bold;'>Your Quoteation has been submitted successfully!</p>",
        icon: "success",

        scrollbarPadding: false, // Scrollbar padding disable
      }).then(() => {
        // Scroll behavior ko restore karein
        // document.body.style.overflow = "auto";
        navigate("/"); // Navigate to the Get Quote form
      });
    }
  };

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleSelect = (option) => {
    setSelectedOption(option); // Set selected option
    SetNextButtonStatus(!nextButtonStatus);
  };

  return (
    <div className="container-fluid py-5 ">
      <div
        style={{
          borderColor: "rgb(253,154,75)",
          borderWidth: "2px",
          borderStyle: "solid",
          backgroundColor: "rgb(255,249,245)",
        }}
        className=" rounded-5 p-4"
      >
        {currentStep === 1 && (
          <div>
            <h2
              style={{
                background: "linear-gradient(to right, #FF5E43, #FB992E)",
                WebkitBackgroundClip: "text",
                color: "transparent",
              }}
              className="fs-1 fw-bold"
            >
              Step 1
            </h2>
            <p className="fs-2 fw-bold">
              Where do you want to install the solar panel?
            </p>
            <div className="d-flex flex-column flex-md-row justify-content-center gap-md-3 gap-5">
              {/* Residential Section */}
              <div
                className={`col-md-6 col-sm-12`}
                onClick={() => handleSelect("Residential")}
              >
                <div
                  style={{
                    borderColor:
                      selectedOption === "Residential"
                        ? "rgb(253,154,75)"
                        : "gray",
                    borderWidth: "2px",
                    borderStyle: "solid",
                    overflow: "hidden",
                    height: "380px",
                  }}
                  className="rounded-5 bg-white"
                >
                  <p className="fs-1 fw-semibold px-4  ">Residential</p>
                  <img
                    src={house}
                    style={{
                      width: "100%",
                      height: "100%",
                      marginTop: "-40px",
                    }}
                    alt="Residential"
                  />
                </div>
              </div>

              {/* Industrial Section */}
              <div
                className={`col-md-6 col-sm-12`}
                onClick={() => handleSelect("Industrial")}
              >
                <div
                  style={{
                    borderColor:
                      selectedOption === "Industrial"
                        ? "rgb(253,154,75)"
                        : "gray",
                    borderWidth: "2px",
                    borderStyle: "solid",
                    overflow: "hidden",
                    height: "380px",
                  }}
                  className="rounded-5 d-flex flex-column bg-white justify-content-center"
                >
                  <p className="fs-1 fw-semibold px-4 mb-2">Industrial</p>
                  <img
                    src={industrial}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    alt="Industrial"
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Render Step2, Step3, Step4 components based on the current step */}
        {currentStep !== 1 && (
          <div>
            {(() => {
              switch (currentStep) {
                case 2:
                  return (
                    <SecondStep
                      nextButtonStatus={nextButtonStatus}
                      SetNextButtonStatus={SetNextButtonStatus}
                    />
                  );
                case 3:
                  return (
                    <ThirdStep
                      nextButtonStatus={nextButtonStatus}
                      SetNextButtonStatus={SetNextButtonStatus}
                    />
                  );
                case 4:
                  return (
                    <FourStep
                      nextButtonStatus={nextButtonStatus}
                      SetNextButtonStatus={SetNextButtonStatus}
                    />
                  );
                default:
                  return null;
              }
            })()}
          </div>
        )}
      </div>

      {/* Buttons */}
      <div className="d-flex  justify-content-end gap-4 mt-3">
        {/* Back Button */}
        {currentStep > 1 && (
          <button
            style={{
              background: "linear-gradient(to right, #FF5E43, #FB992E)",
            }}
            className="btn px-5 fs-5 text-white fw-bold rounded-5 d-inline "
            onClick={handleBack}
          >
            Back
          </button>
        )}

        {/* Next Button */}
        <button
          style={{
            background:
              // selectedOption || currentStep > 1
              nextButtonStatus || currentStep == 3
                ? "linear-gradient(to right, #FF5E43, #FB992E)"
                : "gray",
            // pointerEvents: selectedOption || currentStep > 1 ? "auto" : "none",
            pointerEvents:
              nextButtonStatus || currentStep == 3 ? "auto" : "none",
          }}
          className="btn px-5 fs-5 text-white fw-bold rounded-5 d-inline "
          onClick={handleNext}
        >
          {currentStep == 4 ? "Submit" : "Next"}
        </button>
      </div>
    </div>
  );
};

export default GetQuote;

// import React, { useState } from "react";
// import "./getquote.css";
// import house from "../assets/Getquotes/house.png";
// import industrial from "../assets/Getquotes/industrial.png";

// const GetQuote = () => {
//   const [currentStep, setCurrentStep] = useState(1);
//   const [selectedOption, setSelectedOption] = useState(null);

//   const handleNext = () => {
//     if (currentStep < 4) setCurrentStep(currentStep + 1);
//   };

//   const handleBack = () => {
//     if (currentStep > 1) setCurrentStep(currentStep - 1);
//   };

//   const handleSelect = (option) => {
//     setSelectedOption(option);
//   };

//   return (
//     <div className="container-fluid py-5">
//       <div
//         style={{
//           borderColor: "rgb(253,154,75)",
//           borderWidth: "2px",
//           borderStyle: "solid",
//           backgroundColor: "rgb(255,249,245)",
//         }}
//         className="main rounded-5 p-4"
//       >
//         {currentStep === 1 && (
//           <div>
//             <h2
//               style={{
//                 background: "linear-gradient(to right, #FF5E43, #FB992E)",
//                 WebkitBackgroundClip: "text",
//                 color: "transparent",
//               }}
//               className="fs-1 fw-bold"
//             >
//               Step 1
//             </h2>
//             <p className="fs-2 fw-bold">
//               Where do you want to install the solar panel?
//             </p>
//             <div className="d-flex flex-column flex-md-row justify-content-center gap-md-3 gap-5">
//               <div
//                 className={`col-md-6 col-sm-12`}
//                 onClick={() => handleSelect("Residential")}
//               >
//                 <div
//                   style={{
//                     borderColor:
//                       selectedOption === "Residential"
//                         ? "rgb(253,154,75)"
//                         : "gray",
//                     borderWidth: "2px",
//                     borderStyle: "solid",
//                     overflow: "hidden",
//                     height: "380px",
//                   }}
//                   className="rounded-5 bg-white"
//                 >
//                   <p className="fs-1 fw-semibold px-4">Residential</p>
//                   <img
//                     src={house}
//                     style={{ width: "100%", height: "100%" }}
//                     alt="Residential"
//                   />
//                 </div>
//               </div>
//               <div
//                 className={`col-md-6 col-sm-12`}
//                 onClick={() => handleSelect("Industrial")}
//               >
//                 <div
//                   style={{
//                     borderColor:
//                       selectedOption === "Industrial"
//                         ? "rgb(253,154,75)"
//                         : "gray",
//                     borderWidth: "2px",
//                     borderStyle: "solid",
//                     overflow: "hidden",
//                     height: "380px",
//                   }}
//                   className="rounded-5 bg-white"
//                 >
//                   <p className="fs-1 fw-semibold px-4">Industrial</p>
//                   <img
//                     src={industrial}
//                     style={{ width: "100%", height: "100%" }}
//                     alt="Industrial"
//                   />
//                 </div>
//               </div>
//             </div>
//             <button
//               onClick={handleNext}
//               className="btn btn-primary mt-3"
//             >
//               Next
//             </button>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default GetQuote;
